const enums = require('../../Enums')
const AddShowcaseRequest = require('../../AddShowcaseRequest')

class AddLayout2Request extends AddShowcaseRequest {

  /**
   *
   * @param perPage
   * @param pageCount
   * @param sortKey
   * @param shopifyCollectionId
   * @return {AddLayout2Request}
   */
  setBody(perPage, pageCount, sortKey, shopifyCollectionId) {
    this.perPage = parseInt(perPage)
    this.pageCount = parseInt(pageCount)
    this.sortKey = sortKey || ''
    this.shopifyCollectionId = shopifyCollectionId || ''
    return this
  }

  /**
   *
   * @return {object}
   */
  toParams() {

    return this.toParamsForAdd({
      per_page: this.perPage,
      page_count: this.pageCount,
      sort_key: this.sortKey,
      source_type: enums.source_type.SHOPIFY_COLLECTION,
      source_data: {
        shopify_collection_id: this.shopifyCollectionId
      }
    })
  }
}

module.exports = AddLayout2Request;
